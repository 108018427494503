import React from 'react'
import Layout from '../components/layout-v2';


const Page = () => (

    <Layout>
        <section className="section-home">

            <div id="root">
                <div className="ae af">


                    <main id="main">
                        <div
                            className="wcb3-billboard-cta b5 b6 b7 b8 b9 ba bb ae bx be jg bg bh bi bj c0 bl ji bn c2 bp jk br">
                            <div className="bj bs bt">
                                <h1>Safety Regulations</h1><br/>
                                <p>Drivers are required to carry a current DOT Physical Exam Card, follow DOT rules and
                                    regulations, and maintain a travel log. Some states require that they be certified
                                    for all school-sponsored trips, grades 12 and under. Motor Coaches are not required
                                    by state or federal law to have seatbelts.</p>
                                <p>Individual owner operators including Taxi, Black Car, Limo drivers and Fleet owned
                                    operators are vetted through local city and state government background checks. </p>
                                <p>Gig (Lyft/Uber) type drivers who drive with us will be required to obtain a FOID card
                                    (Firearm Ownership Identification) card. BellMe believes this is the highest
                                    level of background check. We do not promote gun ownership, but rely on each State
                                    to inspect and vet the safest drivers. Driver safety is also verified with DMV
                                    (Driver Motor Vehicle) records in alliance with our insurance partners.</p>
                                <p>More bus affiliates are either retrofitting seat belts into their older vehicles or
                                    buying new buses with seat belts installed. The United States Department of
                                    Transportation (USDOT) regulates the United States charter bus industry.
                                </p>
                                <p>The DOT regulation 395.10 restricts the number of hours the driver can operate the
                                    vehicle.
                                </p>
                                <ul>
                                    <li>10 Hour Rule. Cannot drive more than 10 hours following 8 consecutive hours off
                                        duty (except in emergencies)
                                    </li>
                                    <li>15 Hour Rule. After 15 hours on-duty (driving and non-driving tasks), the driver
                                        cannot continue operating the vehicle until 8 consecutive hours off-duty.
                                    </li>
                                    <li>70 Hour Rule. Driving cannot exceed 70 hours for 8 consecutive days.</li>
                                </ul>
                                <p>Canadian drivers are required to maintain a log for miles and hours of service.
                                    Alcohol is not allowed.</p>
                                <p>The Commercial Vehicle Drivers Hours of Service Regulations (SOR/2005-313) Act
                                    restricts driving time.</p>
                                <ul>
                                    <li>The drivers are not allowed to work more than 13 hours in a day.</li>
                                    <li>After driving 13 hours in a day, at least 8 consecutive hours of off-duty time
                                        is required before driving again.
                                    </li>
                                    <li>Drivers must have at least 10 hours off-duty per day. Daily off-duty time must
                                        include 2 hours that do not form part of an 8 consecutive hour off-duty period.
                                    </li>
                                    <li>The total driving time in 2 days cannot exceed 26 hours.</li>
                                </ul>
                            </div>
                            <br/>

                        </div>
                    </main>
                </div>
            </div>
        </section>
    </Layout>
);

export default Page;
